import React from 'react';
import { Player, Controls } from "@lottiefiles/react-lottie-player";


export const Rounded = ({height, width}) => {
  return (
    <div>
        <Player
                autoplay
                loop
                src="https://assets4.lottiefiles.com/packages/lf20_HYwuZB.json"
                style={{ height: height, width: width }}
              >
                <Controls
                  visible={false}
                  buttons={["play", "repeat", "frame", "debug"]}
                />
              </Player>
             
    </div>
  )
}



export const Dots = ({height, width}) => {
  return (
    <div>
        <Player
                autoplay
                loop
                src="https://lottie.host/0ccecab5-eceb-4268-9c2a-ec8066f05bf8/RlQVbXa7lK.json"
                style={{ height: height, width: width }}
              >
                <Controls
                  visible={false}
                  buttons={["play", "repeat", "frame", "debug"]}
                />
              </Player>
             
    </div>
  )
}

export const Blocks = ({height, width}) => {
  return (
    <div>
        <Player
                autoplay
                loop
                src="https://assets4.lottiefiles.com/packages/lf20_HYwuZB.json"
                style={{ height: height, width: width }}
              >
                <Controls
                  visible={false}
                  buttons={["play", "repeat", "frame", "debug"]}
                />
              </Player>
             
    </div>
  )
}

export const UDisplay = () => {
  return (
    <div>
        <Player
          autoplay
          loop
          src="https://assets7.lottiefiles.com/packages/lf20_jcM7b2.json"
          style={{ height: "300px", width: "300px" }}
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[300px] "
        >
          <Controls
            visible={false}
            buttons={["play", "repeat", "frame", "debug"]}
          />
        </Player>
             
    </div>
  )
}