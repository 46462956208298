import React from 'react'

const UnderConstruction = () => {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
        <div className="wrapp">
            <p>WE'RE SORRY FOR THIS INCONVENIENCE</p>
            <p>The Page is Still Under Construction</p>
        </div>

    </div>
  )
}

export default UnderConstruction