import React from 'react';
import './App.css';
import Routers from './routes/routers';
import Navigation from './components/navigation/Navigation';

function App() {
  return (
    <div className="App w-full flex flex-col items-center relative">
      <Navigation/>
     <Routers/>
    </div>
  );
}

export default App;
