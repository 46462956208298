import React, { useState, useEffect } from "react";
import logo from "../../assets/img/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import "./Navigation.css";

const Navigation = () => {
  const [showNavBar, setShowNavBar] = useState(false);
  const [showNavItems, setShowNavItems] = useState(false);

  const handleShow = () => {
    if (!showNavBar) {
      setShowNavBar(true);
      setTimeout(() => {
        setShowNavItems(true);
      }, 200); // delay should be the same as the navbar transition duration
    } else {
      setShowNavItems(false);
      setTimeout(() => {
        setShowNavBar(false);
      }, 100); // delay should be the same as the nav-items fade out animation duration
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
  }, []);

  return (
    <nav
      className={`navBar 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-full absolute 2xl:top-10 xl:top-10 lg:top-10 -top-0 z-[999] 2xl:px-0 xl:px-0 lg:px-0 px-3 py-5 transition-all duration-300 2xl:bg-transparent xl:bg-transparent lg:bg-transparent bg-[#065fa6] ${
        showNavBar ? "navBar-expand" : "navBar-collapse"
      }`}
    >
      <div className="navBar-wrapper w-full flex justify-between items-center ">
        <div className="navbar-brand ">
          <img src={logo} alt="" className="w-[180px] " />
        </div>
        <div className="navbar-nav 2xl:block xl:block lg:block hidden">
          <div className="nav-item flex gap-5">
            {/* <a href="/about" className="nav-link hover:text-yellow-400">
          About
        </a>
        <a href="/bridge" className="nav-link hover:text-yellow-400">
          Bridge
        </a>
        <a href="/community" className="nav-link hover:text-yellow-400">
          Community
        </a>
        <a href="/explore" className="nav-link hover:text-yellow-400">
          Explorer
        </a>
        <a href="/more" className="nav-link hover:text-yellow-400">
          More
        </a> */}
            <a
              href="/migration"
              className="nav-link hover:text-yellow-300"
              style={{ color: "yellow" }}
            >
              Migration
            </a>
          </div>
        </div>
        <div className="burger 2xl:hidden xl:hidden lg:hidden block">
          <button onClick={handleShow}>
            <GiHamburgerMenu className="text-3xl text-white" />
          </button>
        </div>
      </div>
      {showNavItems && (
        <div
          className={`navbar-nav 2xl:hidden xl:hidden lg:hidden block mt-5 ${
            showNavItems ? "nav-item-show" : "nav-item-hide"
          }`}
        >
          <div
            className={`nav-item flex flex-col items-start gap-5 transition-all duration-700 delay-500 `}
          >
            {/* <a href="/about" className="nav-link hover:text-yellow-400">
          About
        </a> */}
            {/* <a href="/bridge" className="nav-link hover:text-yellow-400">
          Bridge
        </a>
        <a href="/community" className="nav-link hover:text-yellow-400">
          Community
        </a>
        <a href="/explore" className="nav-link hover:text-yellow-400">
          Explorer
        </a>
        <a href="/more" className="nav-link hover:text-yellow-400">
          More
        </a> */}
            <a
              href="/migration"
              className="nav-link hover:text-yellow-300"
              style={{ color: "yellow" }}
            >
              Migration
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
