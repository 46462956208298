import React from "react";
import logo3d from "../assets/img/logo3d.png";
import trid from "../assets/img/3d.png";
import hand from "../assets/img/hand.png";
import motivate from "../assets/img/motivate.png";

const AboutPage = () => {
  return (
    <>
      <section id="home_section" className="Home">
        <div className="container my-lg-5 animate__animated animate__fadeInLeft">
          <h1 className="mb-lg-4 text-yellow text-center">
            About Bitcoin Asset
          </h1>
          <div className="owl-carousel banner owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer">
              <div
                className="owl-stage"
                style={{
                  transform: "translate3d(0px, 0px, 0px)",
                  transition: "all 0s ease 0s",
                  width: "5224px",
                }}
              >
                <div
                  className="owl-item active"
                  style={{ width: "1296px", marginRight: "10px" }}
                >
                  <div className="item">
                    <div className="card card-about my-4 card-blue h-600">
                      <div className="row align-items-center">
                        <div className="col-lg-8">
                          <div className="content py-5">
                            <h3 className="mb-lg-4 text-yellow">
                              Welcome to Decentralized Protocol Era!
                            </h3>
                            <p className="mb-lg-4 text-light">
                              We are now in the brink of new technology and a
                              new connected world between provider, developer,
                              customer, and exchanger.
                            </p>
                            <p>
                              All in one in DeFi protocol! Here we are to
                              improvise the current protocol by introducing BTA
                              (BTAChain Protocol) a completely decentralized web
                              where users are in fully control with higher speed
                              and fewer transaction fees.
                            </p>
                            <p>
                              Not only creating the advanced protocol, with
                              BTAChain will help even more developers to build
                              dapp on our user-friendly BTA blockchain
                              ecosystem.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="">
                            <img src={logo3d} alt="" width="100%" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item"
                  style={{ width: "1296px", marginRight: "10px" }}
                >
                  <div className="item">
                    <div className="card card-about my-4 card-blue h-600">
                      <div className="row align-items-center my-3">
                        <div className="col-lg-4">
                          <div className="">
                            <img src={trid} alt="" width="100%" />
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="content py-5">
                            <h3 className="mb-lg-4 text-yellow">
                              Evolution! Yes, It’s the keyword.
                            </h3>
                            <p className="mb-lg-4 text-light">
                              Our team is always evolving to provide convenience
                              in terms of the ease of our user interface and
                              security. We will not stop innovating to provide
                              the best and create a compact community
                            </p>
                            <p>
                              Our support system will always be happy to help
                              both Token users and developers to integrate with
                              each other in communication and in terms of
                              building Token on top of our protocol. Our mission
                              is to create a win-win solution for all parties
                              involved.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item"
                  style={{ width: "1296px", marginRight: "10px" }}
                >
                  <div className="item">
                    <div className="card card-about my-4 card-blue h-600">
                      <div className="row align-items-center">
                        <div className="col-lg-8">
                          <div className="content py-5">
                            <h3 className="mb-lg-4 text-yellow">About Us</h3>
                            <p>
                              Btachain protocol was built by an IT team who has
                              been an expert in block chain for more than 9
                              years and has solid management with the knowledge
                              of leaders who are experts in this field, with our
                              presence, we believe that we can be a leader and
                              market mover in the crypto- currency market in the
                              future, and has an IT team spread across several
                              countries.
                            </p>
                            <p>
                              The BTA chain protocol will focus on the
                              cryptocurrency-based financial sector, as well as
                              a transparent bridge connecting transactions
                              between blockchain networks.
                            </p>
                            <p>
                              We always prepare for all major changes and
                              welcome those changes by adapting to the new era
                              of the cryptocurrency world, innovation will
                              always be prepared for future changes in the
                              cryptocurrency market.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="">
                            <img src={hand} alt="" width="100%" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="owl-item"
                  style={{ width: "1296px", marginRight: "10px" }}
                >
                  <div className="item">
                    <div className="card card-about my-4 card-blue h-600">
                      <div className="row align-items-center">
                        <div className="col-lg-4">
                          <div className="">
                            <img src={motivate} alt="" width="100%" />
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="content py-5">
                            <h3 className="mb-lg-4 text-yellow">Motivation</h3>
                            <p>
                              Our Motivation is that all people in this world
                              can enjoy the euphoria of Bitcoin 10 years ago and
                              can make BTA their long-term asset and enjoy the
                              benefits of price increases and the ease of user
                              interface and all the facilities that will be
                              provided.
                            </p>
                            <p>
                              We will apply the idea of bitcoin itself and
                              re-recreate history. That is what motivates us to
                              provide BTA as a solution for those who have lost
                              momentum or want to feel the glory of bitcoin
                              since its low price.
                            </p>
                            <p>
                              <b>
                                PRICE is what you pay, Value is what you GET
                              </b>
                            </p>
                            <p>
                              “If You buy Bitcoin 10 years ago, now you get your
                              Value of 50.000 times you're initial.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-nav">
              <button
                type="button"
                role="presentation"
                className="owl-prev disabled"
              >
                <span aria-label="Previous">‹</span>
              </button>
              <button type="button" role="presentation" className="owl-next">
                <span aria-label="Next">›</span>
              </button>
            </div>
            <div className="owl-dots">
              <button type="button" className="owl-dot active">
                <span></span>
              </button>
              <button type="button" className="owl-dot">
                <span></span>
              </button>
              <button type="button" className="owl-dot">
                <span></span>
              </button>
              <button type="button" className="owl-dot">
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div class="container space-bottom-2 animate__animated animate__fadeInLeft animate__delay-1s">
        <div class="row justify-content-center">
          <div class="col-lg-3 my-lg-0 my-2">
            <script
              type="text/javascript"
              src="https://files.coinmarketcap.com/static/widget/currency.js"
            ></script>
            <div
              class="coinmarketcap-currency-widget"
              data-currencyid="19330"
              data-base="USD"
              data-secondary=""
              data-ticker="true"
              data-rank="true"
              data-marketcap="true"
              data-volume="true"
              data-statsticker="false"
              data-stats="USD"
            ></div>
          </div>
          <div class="col-lg-3 my-lg-0 my-2">
            <script
              type="text/javascript"
              src="https://files.coinmarketcap.com/static/widget/currency.js"
            ></script>
            <div
              class="coinmarketcap-currency-widget"
              data-currencyid="1"
              data-base="USD"
              data-secondary=""
              data-ticker="true"
              data-rank="true"
              data-marketcap="true"
              data-volume="true"
              data-statsticker="false"
              data-stats="USD"
            ></div>
          </div>
          <div class="col-lg-3 my-lg-0 my-2">
            <script
              type="text/javascript"
              src="https://files.coinmarketcap.com/static/widget/currency.js"
            ></script>
            <div
              class="coinmarketcap-currency-widget"
              data-currencyid="1027"
              data-base="USD"
              data-secondary=""
              data-ticker="true"
              data-rank="true"
              data-marketcap="true"
              data-volume="true"
              data-statsticker="false"
              data-stats="USD"
            ></div>
          </div>
          <div class="col-lg-3 my-lg-0 my-2">
            <script
              type="text/javascript"
              src="https://files.coinmarketcap.com/static/widget/currency.js"
            ></script>
            <div
              class="coinmarketcap-currency-widget"
              data-currencyid="1839"
              data-base="USD"
              data-secondary=""
              data-ticker="true"
              data-rank="true"
              data-marketcap="true"
              data-volume="true"
              data-statsticker="false"
              data-stats="USD"
            ></div>
          </div>
        </div>
      </div>
      <section className="space-y-4">
    <div className="container mx-auto">
      <div className="flex flex-wrap items-center">
        <div className="w-full lg:w-1/2">
          <img
            src="path_to_your_image/astro.png"
            alt=""
            className="w-full"
          />
        </div>
        <div className="w-full lg:w-1/2 my-2 lg:my-0">
          <h1 className="mb-5">The Bitcoin Asset Mission</h1>
          <p>
            The First Mission is to make the Bitcoin Asset is known by the
            world and eventually makes its price higher, after that, we also
            focus on efficiency and user friendly of our protocol so that
            individuals and companies can make smart contracts under our
            protocol,
          </p>
          <p>
            With transfer transactions that are fast and low cost. The next
            mission is to give a complete ecosystem to the community and
            give a win-win solution to all parties involved.
          </p>
        </div>
        <div className="w-full lg:w-1/2 my-2 lg:my-0">
          <h1 className="mb-5">Vision</h1>
          <p>
            Our vision is to be the leading protocol in the world and can
            provide market needs and always evolve and provide the best,
            most sophisticated in its class.
          </p>
          <p>
            We are aware that to achieve this requires teamwork, both
            protocol providers, token developers, and the token community in
            the world, therefore the synergy created must be mutually
            beneficial, starting from the point of view of price, quality,
            and convenience, and user satisfaction.
          </p>
        </div>
        <div className="w-full lg:w-1/2">
          <img
            src="path_to_your_image/rocket.png"
            alt=""
            className="w-full"
          />
        </div>
      </div>
    </div>
  </section>
  <section className="py-2 bg-gray-200">
    <div className="container mx-auto animate__animated animate__fadeInLeft animate__delay-1s">
      <div className="flex flex-wrap items-center justify-center">
        <div className="w-full lg:w-1/3 text-center">
          <a
            target="_blank"
            href="https://coinmarketcap.com/currencies/bitcoin-asset/"
            rel="noreferrer"
          >
            <img
              src="path_to_your_image/cmc.svg"
              alt=""
              className="w-full"
            />
          </a>
        </div>
        <div className="w-full lg:w-1/3 text-center">
          <a
            target="_blank"
            href="https://testnet.btachain.com/"
            rel="noreferrer"
          >
            <img
              src="path_to_your_image/btachain.svg"
              alt=""
              className="w-full"
            />
          </a>
        </div>
        <div className="w-full lg:w-1/3 text-center">
          <a
            target="_blank"
            href="https://pancakeswap.finance/"
            rel="noreferrer"
          >
            <img
              src="path_to_your_image/pancake.png"
              alt=""
              className="w-full"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
  <section className="space-y-4" id="end_section">
    <div className="container mx-auto">
      <div className="flex flex-wrap items-center text-start">
        <div className="w-full my-5">
          <h1>
            BTA Chain protocol is prepared and built <br /> by an expert
            team in protocol and blockchain field
          </h1>
        </div>
        <div className="w-full lg:w-1/3">
          <div className="p-5 bg-blue-500 text-white">
            <h2 className="text-yellow-500 mb-5">
              Bitcoin Asset <br /> on BSC Protocol
            </h2>
            <p>
              Bitcoin Asset is a token built under the BSCscan.com protocol
              (Binance Smart chain) using the Smart Contract BEP-20.
            </p>
            <p>
              We chose the BSC Chain as the start of the Bitcoin Asset (BTA)
              journey because the BSC Chain now is the Top 5 Biggest
              Capitalization in the world. Other than that, BSC provides a
              lower fee.
            </p>
            <p>
              We are just in the first step in many steps toward future
              development. We will provide the best and complete facilities
              to meet all BTA users and in the future for BTA network users.
            </p>
          </div>
        </div>
        <div className="w-full lg:w-2/3">
          <div className="p-5 bg-blue-500 text-white">
            <h2 className="text-yellow-500 mb-5">BTA Chain Protocol</h2>
            <p>
              The BTA Chain Protocol was developed by the BTA team itself
              without the interference of any other protocol team, we have a
              reliable team in this field. The wallets used in BTA-chain can
              be synchronized to several protocols on the market eventually.
            </p>
            <p>
              When the price of tokens goes up to a higher level, every
              developer will start looking for our protocol and we will
              provide open source at GitHub for everyone.
            </p>
            <p>
              We do everything to present the best and friendly user
              interface both for common user and Crypto developers. BTA
              itself will function as a utility Token, in the next step
              every Token based on our protocol will enjoy other benefits of
              holding BTA Token.
            </p>
            <p>
              This will be our win-win solution for BTA community users,
              crypto developer and user of other crypto developers based on
              the BTA-chain protocol.
            </p>
            <p>
              Our complete BTA protocol and ecosystem will be maximalized to
              allow the community and user to take advantage of the easy and
              low fee BTA Protocol:
            </p>
            <ul className="list-disc list-inside">
              <li>
                Users are free to create any tokenization, financial
                products, and digital assets on BTA protocol
              </li>
              <li>
                Users can operate these in a simple and friendly UI and
                tooling ecosystem (user-friendly and great support system)
              </li>
              <li>
                Provide NFT based chain in BTA protocol for everyone is
                needed.
              </li>
              <li>
                Our team never stop innovating and providing all market’s
                needs
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="space-y-4" id="potential">
    <div className="container mx-auto">
      <div className="flex flex-wrap items-center text-start">
        <div className="w-full">
          <h1>
            Get in touch with <br />
            the Bitcoin Asset Team
          </h1>
        </div>
        {Array(3)
          .fill()
          .map((_, i) => (
            <div key={i} className="w-full lg:w-1/3 text-center mt-5">
              <a
                href="https://t.me/bitcoinassetprotocol"
                className="no-underline"
                target="_blank"
                rel="noreferrer"
              >
                <div className="rounded-lg shadow-lg bg-blue-500 p-3 my-3 flex items-center">
                  <div className="w-1/4">
                    <i className="uil uil-telegram text-4xl"></i>
                  </div>
                  <div className="w-3/4 text-start">
                    <h6>For General Chat</h6>
                    <p>@bitcoinassetprotocol</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
      </div>
    </div>
  </section>
    </>
  );
};

export default AboutPage;
