import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import Redenomination from "../pages/Redenomination";
import UnderConstruction from "../pages/UnderConstruction";

const Routers = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/bridge" element={<UnderConstruction />} />
        <Route path="/community" element={<UnderConstruction />} />
        <Route path="/explore" element={<UnderConstruction />} />
        <Route path="/more" element={<UnderConstruction />} />
        <Route path="/migration" element={<Redenomination />} />
        <Route path="/faq" element={<UnderConstruction />} />
        <Route path="/contact" element={<UnderConstruction />} />
        <Route path="/roadmap" element={<UnderConstruction />} />
        <Route path="/404" element={<UnderConstruction />} />
        <Route path="/brandassets" element={<UnderConstruction />} />
      </Routes>
    </>
  );
};

export default Routers;
