import React, { useState, useRef, useEffect } from "react";
import layer1 from "../assets/img/elements.png";
import layer2 from "../assets/img/fly.png";
import world from "../assets/img/world.png";
import LBANK from "../assets/img/lbank.svg";
import nomics from "../assets/img/nomics.svg";
import live from "../assets/img/livecoin.png";
import digital from "../assets/img/dcp.svg";
import tokenn from "../assets/img/tokenncoin-dark.png";
import cmc from "../assets/img/cmc.svg";
import btachain from "../assets/img/btachain.svg";
import pancake from "../assets/img/pancake.png";
import paprika from "../assets/img/coinpaprika.svg";
import github from "../assets/img/github.png";
import wallet from "../assets/img/wallet.png";
import low from "../assets/img/low-fee.png";
import walletFriendly from "../assets/img/wallet-friendly.png";
import crosschain from "../assets/img/crosschain.png";
import govern from "../assets/img/governance.png";
import BTA from "../assets/img/bitcoinasset.jpg";
import { Rounded as Preloader } from "../components/Preloader/Preloader";
import "./home.css";
import CoinTicker from "./pageComponents/CoinTicker";
import vids from "../assets/Evolution (1).mp4";
import Footer from "../components/footer/Footer";

const HomePage = () => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);


  const handleVideoLoad = () => {
    setLoading(true);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute("playsinline", "");
    }
  }, []);

  const handleCloseAnnouncement = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShow(false);
  }

  return (
    <div className="w-full flex flex-col items-center relative mt-10">
      {show && <div className="absolute w-[350px] h-auto p-5 rounded-2xl bg-white z-[1000] top-8 left-1/2 -translate-x-1/2 drop-shadow-md shadow-lg shadow-black">
        <div className="content-top relative flex justify-center items-center">
          <button onClick={handleCloseAnnouncement} className="text-sm font-bold bg-red-500 p-2 rounded-xl text-white absolute top-3 right-3 z-[1000]">Close</button>
          <div className={`loader my-8 ${loading ? "hidden" : ""}`}>
            <Preloader height={"200px"} width={"200px"} />
            <p className="loader animate-bounce text-center text-md font-bold text-white">
              Loading..
            </p>
          </div>

          <video
            ref={videoRef}
            autoPlay="true"
            loop="loop"
            muted="muted"
            src={vids}
            className={`NFT ${loading ? "" : "hidden"}`} // Hide the video when loading is true
            onLoadedData={handleVideoLoad}
          >
            <source src={vids} />
          </video>
        </div>
        <div className="text flex flex-col gap-3">
        <p className="text-3xl font-black">Announcement :</p>

        <p className="text-lg font-black">Good news esteemed BTA community members</p>
        <p className="text-start font-medium">
        We would like to formally announce the extension of the migration period to BTA 2.0 tokens until May 20, 2024
        </p>

        <p className="text-start font-medium">
        Best regards, The BTA Team.

        </p>

       
        
        <p className="text-start font-semibold text-xl">Let's Make BTA great again.  </p>
        <span className="border-t-2 border-gray-800"/>
        
        <p className="text-sm font-bold mt-3">As the migration into ver 2.0, the web will undergo many changes. Stay with us!</p>
        </div>
      </div>}

      <section id="Home" className="Home w-full">
        <div className="wrapper 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-[95%] mx-auto  grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 2xl:mt-10 xl:mt-10 lg:mt-10 mt-16">
          <div className="left  text-start block">
            <i>
              <b className="text-yello text-[4rem] font-[900] leading-[3.625rem]">
                BITCOIN ASSET
              </b>
            </i>
            <p className="2xl:text-[2rem] xl:text-[2rem] lg:text-[2rem] text-[24px] font-[500] font-ubuntu my-1">
              BTA Chain Protocol
            </p>
            <p className="text-[24px] font-[500] font-ubuntu text-white/90 my-3">
              Our Protocol is prepared and built by an expert team in protocol
              and blockchain field
            </p>
            <span className="text-yello flex gap-2 items-center font-semibold my-2">
              {" "}
              <p className="py-2 px-3 rounded-lg bg-blacky text-yellowy underline whitespace-nowrap font-bold">
                <a
                  href="https://bscscan.com/address/0x5D2436c74b8Ab54F3199f76a0761D30ca64A0827"
                  target="_blank"
                  rel="noreferrer"
                >
                  Smart contract BSC
                </a>
              </p>{" "}
              <small>BuySell Slippage 3%</small>
            </span>
            <div className="buttons text-center gap-6 py-3 grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 2xl:mt-4 xl:mt-4 lg:mt-4 mt-7 ">
              <a
                href="/roadmap"
                className="bg-yellowy text-blacky rounded-xl py-5 w-full hover:bg-blacky hover:text-yellowy hover:rounded-[50px] transition-all duration-500"
              >
                Learn About Roadmap
              </a>
              <a
                href="bitcoinasset-whitepaper-v.1.1.pdf"
                target="pdf-frame"
                className="bg-yellowy text-blacky rounded-xl py-5 w-full hover:bg-blacky hover:text-yellowy hover:rounded-[50px] transition-all duration-500"
              >
                View Whitepaper
              </a>
            </div>
          </div>
          <div className="right relative flex items-center justify-center">
            <div className="wrapper relative w-[400px] h-[400px] flex ">
              <div className="layerFront ">
                <img
                  src={layer1}
                  alt=""
                  className="updown h-[400px] absolute place-self-center z-[1]"
                />
              </div>
              <div className="image-content ">
                <img
                  src={layer2}
                  alt=""
                  className="h-[400px] absolute place-self-center z-10"
                />
                <div className="ro-animate 2xl:block xl:block lg:block hidden ">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CoinTicker />

      <section id="intro" className="section-space py-[150px]">
        <div className="wrapper 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-[95%] grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 mx-auto">
          <div className="left updown">
            <img src={world} alt="" className="relative updown" />
          </div>
          <div className="right place-self-center text-start">
            <p className="text-[2.5rem] font-semibold mb-8">
              Introduction of <br />
              Bitcoin Asset (BTA)
            </p>
            <p className="text-xl">
              BITCOIN ASSET, as the name implies, Bitcoin Assets, we hope that
              with the presence of BTA, all of us here can feel that we have
              bitcoin assets and get bitcoin momentum like 10 years back.
            </p>
            <p className="text-xl my-5">
              Bitcoin itself is the mother of all coins that exist today.
              Therefore, we also rely on BITCOIN to create the current euphoria
              of the market but with new sophistication and much more complete
              protocols and features.
            </p>
            <p className="text-xl">
              We combine bitcoin with the current developments, namely protocol,
              Defi, swap and NFT. thus, BTA is an asset that deserves to be
              valued the higher in the future and is worthy of long-term storage
              like bitcoin.
            </p>
          </div>
        </div>
      </section>

      <section
        id="partner"
        className="section-space py-2 w-full"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="contain 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-[95%] mx-auto animate__animated animate__fadeInLeft animate__delay-1s">
          <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 mt-2 gap-5 place-self-center">
            <div className="text-center flex items-center justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://coinmarketcap.com/currencies/bitcoin-asset/"
              >
                <img src={cmc} alt="" className="w-full" />
              </a>
            </div>
           
            <div className="text-center flex items-center justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://pancakeswap.finance/"
              >
                <img src={pancake} alt="" className="w-full" />
              </a>
            </div>
            <div className="text-center flex items-center justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://coinpaprika.com/coin/bta-bitcoin-asset/"
              >
                <img src={paprika} alt="" className="w-full" />
              </a>
            </div>
          </div>
          <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 mt-2">
            <div className="text-center flex items-center justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://nomics.com/assets/bta2-bitcoinasset"
              >
                <img src={nomics} alt="" className="w-full" />
              </a>
            </div>
            <div className="text-center my-3 flex items-center justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.livecoinwatch.com/price/BitcoinAsset-BTA"
              >
                <img src={live} alt="" className="w-full" />
              </a>
            </div>
            <div className="text-center flex items-center justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.lbank.info/exchange/bta/usdt"
              >
                <img src={LBANK} alt="" className="w-full" />
              </a>
            </div>
          </div>
          <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 mt-2">
            <div className="text-center flex items-center justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://digitalcoinprice.com/coins/bitcoin-asset"
              >
                <img src={digital} alt="" className="w-full" />
              </a>
            </div>
            <div className="text-center flex items-center justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://tokenmarketcaps.com/coins/bitcoin-asset"
              >
                <img src={tokenn} alt="" className="w-full" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section-space w-full" id="details">
        <div className="side-el 2xl:block xl:block lg:block hidden"></div>
        <div className="wrapper 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-[95%] flex flex-col justify-center items-center mx-auto">
          <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-8 z-[100] text-justify animate__animated animate__fadeInLeft animate__delay-1s justify-content-center">
            <div className="details-items group my-3 p-5">
              <img
                // src="https://bitcoinasset.io/assets/img/content/github.png"
                src={github}
                alt=""
                width="100%"
                className="p-10 transition-all duration-300 ease-linear group-hover:ease-linear group-hover:p-5 group-hover:transition-all group-hover:duration-300"
              />
              <p className="text-xl font-semibold mb-3">
                Smart Contract in our Protocol – open-source on Github
              </p>
              <p className="text-lg">
                We make it easy for all Token developers who want to use our
                protocol. for that we feature open-source on GitHub and our
                support system will help as much as we can provide what is
                needed. We invite all developers who see the opportunity from
                BTA to join us and create win-win solutions with all customers.
              </p>
            </div>
            <div className="details-items group my-3 p-5">
              <img
                // src="https://bitcoinasset.io/assets/img/content/wallet.png"
                src={wallet}
                alt=""
                width="100%"
                className="p-10 transition-all duration-300 ease-linear group-hover:ease-linear group-hover:p-5 group-hover:transition-all group-hover:duration-300"
              />
              <p className="text-xl font-semibold mb-3">
                Environmentally Friendly
              </p>
              <p className="text-lg">
                We have shifted to New Method to our blockchain where it is
                designed to be Environment friendly, the blockchain will run on
                the server constantly and also the block is automatically
                printed, without using a single party to run it.
              </p>
              <p className="text-lg">
                The New Method in our blockchain will continue run on the server
                without having to active computer for 24 hours , It is so
                environment friendly.
              </p>
            </div>
            <div className="details-items group my-3 p-5">
              <img
                // src="https://bitcoinasset.io/assets/img/content/low-fee.png"
                src={low}
                alt=""
                width="100%"
                className="p-10 transition-all duration-300 ease-linear group-hover:ease-linear group-hover:p-5 group-hover:transition-all group-hover:duration-300"
              />
              <p className="text-xl font-semibold mb-3">
                Low fees and fast transfer
              </p>
              <p className="text-lg">
                In line with the development of public knowledge about crypto.
                It requires a low fee to make it easier for new users to enjoy
                easy and fast transactions. Likewise, large institutions carry
                out millions of transactions a day, thus our initial goal was to
                provide the lowest possible fee.
              </p>
              <p className="text-lg">
                Every Bitcoin Asset transaction or token registered on BTAChain
                is subject to a very low fee starting from 1 GWEI, this is the
                best offer from the btachain protocol network.
              </p>
            </div>
            <div className="details-items group my-3 p-5">
              <img
                // src="https://bitcoinasset.io/assets/img/content/wallet-friendly.png"
                src={walletFriendly}
                alt=""
                width="100%"
                className="p-10 transition-all duration-300 ease-linear group-hover:ease-linear group-hover:p-5 group-hover:transition-all group-hover:duration-300"
              />
              <p className="text-xl font-semibold mb-3">Friendly Wallets</p>
              <p className="text-lg">
                Our wallet address is very user friendly, Btachain is a fork of
                ethereum, where the ethereum wallet can function as a recipient
                / sender of Bitcoin Assets, coins or tokens under our network.
              </p>
              <p className="text-lg">
                The use of this simple wallet is a new breakthrough in the world
                of crypto- currency, where people don't have to bother creating
                wallet addresses over and over again.
              </p>
            </div>
            <div className="details-items group my-3 p-5">
              <img
                // src="https://bitcoinasset.io/assets/img/content/crosschain.png"
                src={crosschain}
                alt=""
                width="100%"
                className="p-10 transition-all duration-300 ease-linear group-hover:ease-linear group-hover:p-5 group-hover:transition-all group-hover:duration-300"
              />
              <p className="text-xl font-semibold mb-3">
                Cross-Chain Transaction
              </p>
              <p className="text-lg">
                The btachain network can be connected to various protocols with
                a bridge system built using smart contracts.
              </p>
              <p className="text-lg">
                Crosschain transactions in our blockchain run transparently,
                openly and can be monitored by anyone, this is to ensure
                transactions run openly and transparently.
              </p>
            </div>
            <div className="details-items group my-3 p-5">
              <img
                // src="https://bitcoinasset.io/assets/img/content/governance.png"
                src={govern}
                alt=""
                width="100%"
                className="p-10 transition-all duration-300 ease-linear group-hover:ease-linear group-hover:p-5 group-hover:transition-all group-hover:duration-300"
              />
              <p className="text-xl font-semibold mb-3">Governance</p>
              <p className="text-lg">
                In our btachain network developers can build their own
                government through BTA20 smart contracts, such as staking,
                lending, borrowing, etc, by deploying their smart contracts into
                our network.
              </p>
            </div>
          </div>
          <div className="my-5 text-center">
            <a
              href="/about"
              className="group mt-5 text-2xl font-semibold px-10 py-5 transition-all duration-200 ease-linear flex items-center justify-center rounded-2xl bg-[#0877d0] hover:bg-[#065594] hover:rounded-[50px] hover:px-9"
            >
              <i className="uil uil-globe"></i>{" "}
              <p className="text-white group-hover:text-yellowy transition-all duration-200 group-hover:transition-all group-hover:duration-300">
                {" "}
                Discover BTA Chain{" "}
              </p>
            </a>
          </div>
        </div>
        <div className="side-el left 2xl:block xl:block lg:block hidden"></div>
      </section>

      <section
        className="section-space w-full"
        id="potential"
        style={{ backgroundColor: "rgb(12,134,223)" }}
      >
        <div className="container 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-[95%] mx-auto animate__animated animate__fadeInLeft animate__delay-1s">
          <div className="flex 2xl:flex-row xl:flex-row lg:flex-row flex-col items-center justify-center ">
            <div className="flex flex-col">
              <div className="px-5">
                <img
                  // src="https://bitcoinasset.io/assets/img/content/bitcoinasset.jpg"
                  src={BTA}
                  alt=""
                  className="w-[70%]"
                />
              </div>
            </div>
            <div className="flex flex-col w-[90%]">
              <h1 className="mb-4 text-yellowy 2xl:text-3xl xl:text-3xl lg:text-2xl text-lg font-semibold text-start">
                BTA chain is an open-source protocol in smart contract
                documentation for everyone
              </h1>
              <p className="text-white 2xl:text-xl xl:text-xl lg:text-lg text-base font-semibold text-justify">
                BTA Chain Protocol is built using high technology and has many
                advantages. Such us our own wallet and future we can create
                token based on NFT with our BTA Protocol.
              </p>
              <p className="text-white 2xl:text-xl xl:text-xl lg:text-lg text-base font-semibold text-justify">
                Besides low fees, we will create a very fast network with an
                integrated server across the country, so bottom-line we will
                fulfill every factor to compete for the market. It will never
                stop evolving because we are on the brink of evolutionary here.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default HomePage;
