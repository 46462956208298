import React from 'react';
import { FaReddit, FaMediumM, FaYoutube, FaFacebookF, FaTwitterSquare, FaGithub, FaTelegram, FaDiscord } from "react-icons/fa";
import logo from "../../assets/img/logo.png";

const Footer = () => {
  return (
    <footer className="pt-[9.375rem] text-center text-white w-full flex flex-col" style={{"backgroundColor": "#0877d0"}}>
     
            <div className="container 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-[95%] mx-auto pb-[2.5rem] animate__animated animate__fadeInLeft animate__delay-1s">
         
                <div className="grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 grid-cols-1 pt-5 mb-5 gap-5 text-start">
        
                    <div className="flex flex-col gap-4">
                        <h5 className="uppercase text-yello font-bold text-2xl">General</h5>
                        <ul className="list-unstyled gap-6 flex flex-col">
                            <li>
                                <a href="/about" className="text-white text-xl font-semibold">About</a>
                            </li>
                            <li>
                                <a href="/faq" className="text-white text-xl font-semibold">FAQ</a>
                            </li>
                            <li>
                                <a href="/contact" className="text-white text-xl font-semibold">Contact</a>
                            </li>
                        </ul>
                    </div>
        
                    <div className="flex flex-col gap-4">
                        <h5 className="uppercase text-yello font-bold text-2xl">Information</h5>
                        <ul className="list-unstyled gap-6 flex flex-col">
                            <li>
                                <a href="/roadmap" className="text-white text-xl font-semibold">Roadmap</a>
                            </li>
                            <li>
                                <a href="https://bscscan.com/token/0x5d2436c74b8ab54f3199f76a0761d30ca64a0827" className="text-white text-xl font-semibold" target="_blank" rel="noreferrer">Smart Contract</a>
                            </li>
                            <li>
                                <a href="bitcoinasset-whitepaper-v.1.1.pdf" target="pdf-frame" className="text-white text-xl font-semibold">Whitepaper</a>
                            </li>
                            <li>
                                <a href="/404" target="_blank" rel="noreferrer" className="text-white text-xl font-semibold">BTAChain</a>
                            </li>
                        </ul>
                    </div>
             
                    <div className="flex flex-col gap-4">
                        <h5 className="uppercase text-yello font-bold text-2xl">Community</h5>
                        <ul className="list-unstyled gap-6 flex flex-col">
                            <li>
                                <a href="/community" className="text-white text-xl font-semibold">Community</a>
                            </li>
                           
                            <li>
                                <a href="/brandassets" className="text-white text-xl font-semibold">Brand Asset</a>
                            </li>
                        </ul>
                    </div>
         
      
                    <div className="flex flex-col gap-4">
                        <a href="https://bitcoinasset.io/" className="iconbottom"><img src={logo} alt="" className="2xl:w-full xl:w-full lg:w-full w-[60%]"/></a>
                    </div>
          
                </div>

                <hr className="my-5"/>

                <section className="text-center 2xl:text-3xl xl:text-3xl lg:text-2xl text-base mb-5 flex justify-center items-center w-full 2xl:gap-10 xl:gap-8 lg:gap-5 gap-1">
                    <a href="https://www.reddit.com/r/bitcoinasset" className="text-white me-4" target="_blank" rel="noreferrer">
                    <i className="uil uil-reddit-alien-alt"> <FaReddit/> </i>
                    </a>
                    <a href="https://bitcoinasset.medium.com/" className="text-white me-4" target="_blank" rel="noreferrer">
                    <i className="uil uil-medium-m"> <FaMediumM/> </i>
                    </a>
                    <a href="https://www.youtube.com/channel/UCzaI1bS6cz5dUWQI55-CZfw" className="text-white me-4" target="_blank" rel="noreferrer">
                    <i className="uil uil-youtube"> <FaYoutube/> </i>
                    </a>
                    <a href="https://www.facebook.com/bitcoinassetx" className="text-white me-4" target="_blank" rel="noreferrer">
                    <i className="uil uil-facebook-f"> <FaFacebookF/> </i>
                    </a>
                    <a href="https://twitter.com/Bitcoinassetx" className="text-white me-4" target="_blank" rel="noreferrer">
                    <i className="uil uil-twitter"> <FaTwitterSquare/> </i>
                    </a>
                    <a href="https://github.com/BitcoinAssetChain" className="text-white me-4" target="_blank" rel="noreferrer">
                    <i className="uil uil-github-alt"> <FaGithub/> </i>
                    </a>
                    <a href="https://t.me/bitcoinassetx" className="text-white me-4" target="_blank" rel="noreferrer">
                    <i className="uil uil-telegram-alt"> <FaTelegram/> </i>
                    </a>
                    <a href="https://discord.com/invite/hZU4kG8RmU" className="text-white me-4" target="_blank" rel="noreferrer">
                    <i className="uil uil-discord"> <FaDiscord/> </i>
                    </a>
                </section>

            </div>

            <div className="text-center p-3" style={{"backgroundColor": "rgba(0, 0, 0, 0.2)"}}>
         
              <a className="text-white" href="/" >Bitcoin Asset</a> © 2022 All Rights Reserved. <span className="ms-3"> | </span> <a href="/" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="text-white mx-3" >Disclaimer</a> |
                <a href="Privacy-Policy.pdf" target="pdf-frame" className="text-white mx-3">Privacy</a> |
                <a href="Terms-of-Service-BTA.pdf" target="pdf-frame" className="text-white mx-3">Terms of Service</a>
            </div>
          
        </footer>
  );
}

export default Footer;
