import React from 'react';
import "./home.css";
import Footer from '../components/footer/Footer';
import icon from '../assets/img/icon/bta.png'
import { FaArrowCircleDown } from "react-icons/fa";

const Redenomination = () => {
  return (
    <>
      <section id="Home" className="Home w-full flex justify-center">
          <div className="wrapper 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-[95%] flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-center gap-8">
            <div className="left w-1/2 bg-sky-600/60 p-8 rounded-xl shadow-md ">
              <div className="wrapp flex flex-col gap-4 text-start">
                <div className="old">
             <p className='text-2xl text-yello font-semibold'>Old BTA</p>
             <p>0x5D2436c74b8Ab54F3199f76a0761D30ca64A0827</p>
             </div>
             <div className="new">
             <p className='text-2xl text-yello font-semibold'>BTA v2 Smart Contract</p>
             <p>0x5D2436c74b8Ab54F3199f76a0761D30ca64A0827</p>
             </div>
<div className="tokenomics w-full mx-auto text-start">
             <p className='text-2xl text-yello font-semibold'>TOKENOMICS BTA v.2.0</p>
             <p>Tokenomic 500 M Supply</p>
             <p>Decimal 21 M</p>
             <p>Developer 10%</p>
             <p>Marketing & Event 5%</p>
             <p>Migration from Old to BTA 2.0 2%</p>
             
             <p>Pool Staking Reward 80%</p>
             </div>
             </div>
            </div>
            <div className="right w-1/2 bg-sky-600/60 p-8 rounded-xl shadow-md">
             <div className="wrapp flex flex-col gap-5">
              <div className="old-input flex flex-col bg-sky-700 p-4 rounded-xl">
                <label htmlFor="old" className='w-full flex justify-between items-center'>
                 <p>Amount to swap</p> <span className='flex gap-3'> <img src={icon} alt="icon" width={24} height={24} /> Old BTA </span>
                </label>
                <input type="text" name='old' placeholder='0' className='outline-none px-3 py-2 rounded-lg bg-transparent text-gray-800'/>
              </div>
              <div className="swap-icon w-full flex justify-center items-center text-3xl">
                <FaArrowCircleDown/>
              </div>
              <div className="new-input flex flex-col bg-sky-700 p-4 rounded-xl">
              <label htmlFor="new" className='w-full flex justify-between items-center'>
                 <p>Amount to receive</p> <span className='flex gap-3'> <img src={icon} alt="icon" width={24} height={24} /> BTA ver 2.0 </span>
                </label>
                <input type="text" name='new' placeholder='0' className='outline-none px-3 py-2 rounded-lg bg-transparent text-gray-800' />
              </div>
              <div className="button-approve">
                <button className='px-3 py-5 bg-yello w-full text-blacky font-black rounded-[50px] inline-flex gap-3  justify-center items-center'>  <img src={icon} alt="icon" width={24} height={24} /> Approve Swap</button>
              </div>
              <div className="swap-info">
                <div className="info-1">
                <p>Exchange Rate</p>
                <p>1000 Old BTA = 1 BTA ver 2.0</p>
                </div>
                {/* <div className="info-2">
                <p>Exchange Rate</p>
                <p>1000 Old BTA = 1 BTA ver 2.0</p>
                </div> */}
              </div>
             </div>
            </div>
          </div>
        </section>
        <Footer/>
    </>
  );
}

export default Redenomination;
