import React, {useEffect} from 'react'

const CoinTicker = () => {
    useEffect(() => {
        // Create a script element for the CoinMarketCap widget
        const script = document.createElement('script');
        script.type = "text/javascript"
        script.src = 'https://files.coinmarketcap.com/static/widget/currency.js';

    
        // Append the script to the document's body
        document.body.appendChild(script);
    
        // Clean up: remove the script when the component unmounts
        return () => {
          document.body.removeChild(script);
        };
      }, []);
  return (
    <section id="coinmarket" className='CoinMarket w-full flex justify-center mt-[-5rem]'>
            <div className="wrapper 2xl:w-[70%] xl:w-[70%] lg:w-[70%] w-[95%] relative grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 grid-cols-1 gap-5 ">
            <div className="coinmarketcap-currency-widget bg-white rounded-xl hover:-translate-y-3 duration-300 transition-all hover:duration-300 hover:transition-all" data-currencyid="19330" data-base="USD" data-secondary="" data-ticker="true" data-rank="true" data-marketcap="true" data-volume="true" data-statsticker="false" data-stats="USD"></div>
            <div className="coinmarketcap-currency-widget bg-white rounded-xl hover:-translate-y-3 duration-300 transition-all hover:duration-300 hover:transition-all" data-currencyid="1" data-base="USD" data-secondary="" data-ticker="true" data-rank="true" data-marketcap="true" data-volume="true" data-statsticker="false" data-stats="USD"></div>
            <div className="coinmarketcap-currency-widget bg-white rounded-xl hover:-translate-y-3 duration-300 transition-all hover:duration-300 hover:transition-all" data-currencyid="1027" data-base="USD" data-secondary="" data-ticker="true" data-rank="true" data-marketcap="true" data-volume="true" data-statsticker="false" data-stats="USD"></div>
            <div className="coinmarketcap-currency-widget bg-white rounded-xl hover:-translate-y-3 duration-300 transition-all hover:duration-300 hover:transition-all" data-currencyid="1839" data-base="USD" data-secondary="" data-ticker="true" data-rank="true" data-marketcap="true" data-volume="true" data-statsticker="false" data-stats="USD"></div>
            </div>
        </section>
  )
}

export default CoinTicker